<template>
    <v-item-group v-model="product.selectedSize" class="d-flex align-start flex-wrap pb-6">
        <v-item 
            v-slot="{ active, toggle }"
            v-for="size in pr3List"
            :key="size.pr3tamanho"
        >
            <v-card
                color="white"
                class="d-flex justify-center align-center mx-1 my-1"
                :elevation="active ? 6 : 0"
                :style="active ? `border: ${parameters.primaryColor} 2px solid !important` : 'border: #dcdcdc 1px solid !important'"
                dark
                max-width="40"
                height="40"
                width="40"
                @click="toggle"
            >
                <v-card
                    dark
                    color="transparent"
                    class="d-flex align-center"
                    height="33"
                    width="33"
                    elevation="0"
                >
                    <div class="button flex-grow-1 text-center" :style="{color: parameters.primaryColor}">{{ size.pr3tamanho }}</div>
                </v-card>
            </v-card>
        </v-item>
    </v-item-group>
</template>

<script>
export default {
    props:{
        pr3List:{
            type: Array,
            required: true
        }
    },
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        product: {
            get() {
                return this.$store.state.product;
            },
        },
        selectedSize: {
            get() {
                return this.$store.state.product.selectedSize;
            },
        },
    },
    watch:{
        selectedSize(val){
            this.chooseSize(this.pr3List[val])
        }
    },
    methods:{
        chooseSize(pr3){
            this.$store.dispatch("product/chooseSize", pr3)
        },
    }
};
</script>

<style>
</style>