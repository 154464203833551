<template>
    <v-item-group
        v-model="product.selectedColor"
        class="d-flex align-start flex-wrap pb-6"
    >
        <v-item
            v-slot="{ active, toggle }"
            v-for="color in pr2List"
            :key="color.pr2opcao"
        >
            <v-card
                color="white"
                class="d-flex justify-center align-center mx-1 my-1"
                :elevation="active ? 6 : 0"
                :style="
                    active
                        ? `border: ${parameters.primaryColor} 2px solid !important`
                        : 'border: #dcdcdc 1px solid !important'
                "
                dark
                max-width="40"
                height="40"
                width="40"
                @click="toggle"
            >
                <v-img 
                    v-if="color.cr1imagem"
                    :src="color.cr1imagem"
                    aspect-ratio="1"
                    contain
                    v-on:error="onImgError(color)"
                />
                <v-card
                    v-else
                    dark
                    :color="color.hexadecimal"
                    height="32"
                    width="32"
                    elevation="0"
                >
                </v-card>
            </v-card>
        </v-item>
    </v-item-group>
</template>

<script>
export default {
    props:{
        pr2List:{
            type: Array,
            required: true
        }
    },
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        product: {
            get() {
                return this.$store.state.product;
            },
        },
        selectedColor: {
            get() {
                return this.$store.state.product.selectedColor;
            },
        },
    },
    watch:{
        selectedColor(val){
            this.chooseColor(this.pr2List[val])
        },
    },
    methods:{
        chooseColor(pr2){
            this.$store.dispatch("product/chooseColor", pr2)
            this.$emit("chooseColor", pr2)
        },
        onImgError(color){
            color.cr1imagem = ""
        }
    }
};
</script>

<style>
</style>