<template>
    <v-container fluid class="pb-14">
        <v-carousel
            v-model="selectedImage"
            show-arrows
            width="100%"
            height="inherit"
            hide-delimiter-background
            hide-delimiters
            style="min-height: 350px !important; max-height: 85% !important"
        >
            <v-carousel-item
                v-for="(image, i) in pr0.lstB2i"
                :key="i"
                :src="image.b2icaminho"
                class="vCarouselItem"
            ></v-carousel-item>
        </v-carousel>
        <p class="pt-4 text-h6 font-weight-bold mb-0">{{ pr0.pr0desc }}</p>
        <p class="caption">Referência {{ pr0.pr2opcao && pr0.pr3tamanho ? `${pr0.pr0produto}-${pr0.pr2opcao}-${pr0.pr3tamanho}` : pr0.pr0produto }}</p>
        <p v-if="login.userLogged" class="text-h4 font-weight-bold pt-5" :style="{color: parameters.primaryColor}">{{ pr0.pr0preco | money }}</p>
        <v-alert v-else outlined :color="parameters.secondaryColor">
            <div>Realize o <a @click="$router.push('/login')" class="text-decoration-underline">login</a> para visualizar o preço do produto</div>
        </v-alert>
        <v-divider class="pb-6"></v-divider>
        <ProductColorTitle :cr1nome="pr0.cr1nome"/>
        <ProductColorSelector :pr2List="pr0.pr2List" @chooseColor="chooseColor"/>
        
        <v-divider class="pb-6"></v-divider>
        <ProductSizeTitle :gr1desc="pr0.gr1desc"/>
        <ProductSizeSelector :pr3List="pr0.pr3List"/>
        
        <v-divider class="pb-6"></v-divider>
        <ProductDescription :pr0ficha="pr0.pr0ficha"/>
        
        <v-divider class="pb-10"></v-divider>
        <RelatedProducts :relatedProducts="pr0.relatedProducts"/>
        
        <ProductBottomSheetVd3List fixed/>
    </v-container>
</template>

<script>
const ProductColorTitle = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/product/details/ProductColorTitle.vue"
    );
const ProductColorSelector = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/product/details/ProductColorSelector.vue"
    );
const ProductSizeTitle = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/product/details/ProductSizeTitle.vue"
    );
const ProductSizeSelector = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/product/details/ProductSizeSelector.vue"
    );
const ProductDescription = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/product/details/ProductDescription.vue"
    );
const RelatedProducts = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/template/RelatedProducts.vue"
    );
const ProductBottomSheetVd3List = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/product/details/ProductBottomSheetVd3List.vue"
    );

export default {
    data: () => ({
        selectedImage: 0
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        pr0: {
            get() {
                return this.$store.state.product.product;
            },
        },
        product: {
            get() {
                return this.$store.state.product;
            },
        },
        login: {
            get() {
                return this.$store.state.login;
            },
        },
    },
    components:{
        ProductColorTitle,
        ProductColorSelector,
        ProductSizeTitle,
        ProductSizeSelector,
        ProductDescription,
        RelatedProducts,
        ProductBottomSheetVd3List,
    },
    methods:{
        chooseColor(pr2){
            if(!pr2){
                this.selectedImage = 0
                return
            }
            const filterImage = b2i => b2i.b2iopcao == pr2.pr2opcao
            let b2i = this.pr0.lstB2i.filter(filterImage)[0]
            if(b2i){
                this.selectedImage = b2i.b2icodigo
            }else{
                this.selectedImage = 0
            }
        }
    }
};
</script>

<style scoped>
    .vCarouselItem >>> .v-carousel__item{
        min-height: 350px !important;
    }
</style>