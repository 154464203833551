<template>
    <v-dialog fullscreen hide-overlay scrollable v-model="product.sheet" transition="dialog-bottom-transition">
        <v-toolbar color="#FDFDFD" class="title" :style="{color: parameters.primaryColor}" elevation="0" absolute>
            <v-toolbar-title>Grade</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark :color="parameters.primaryColor" draggable @click="product.sheet = !product.sheet">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card class="pb-10 pt-12">
            <v-container fluid class="px-0 pb-0">
                <v-subheader class="text-center">Adicione mais itens da grade ao carrinho</v-subheader>
                <v-container>
                    <p class="mb-2 pl-1 text-left"><span class="font-weight-bold">COR: </span>{{ pr0.cr1nome }}</p>

                    <v-item-group v-model="product.selectedColor" class="d-flex align-start flex-wrap">
                        <v-item 
                            v-slot="{ active, toggle }"
                            v-for="color in pr0.pr2List"
                            :key="color.pr2opcao"
                        >
                            <v-card
                                color="white"
                                class="d-flex justify-center align-center mx-1 my-1"
                                :elevation="active ? 6 : 0"
                                :style="active ? `border: ${parameters.primaryColor} 2px solid !important` : 'border: #dcdcdc 1px solid !important'"
                                dark
                                max-width="36"
                                height="36"
                                width="36"
                                @click="toggle"
                            >
                                <v-img 
                                    v-if="color.cr1imagem"
                                    :src="color.cr1imagem"
                                    aspect-ratio="1"
                                    contain
                                    v-on:error="onImgError(color)"
                                />
                                <v-card
                                    v-else
                                    dark
                                    :color="color.hexadecimal"
                                    height="30"
                                    width="30"
                                    elevation="0"
                                >
                                </v-card>
                            </v-card>
                        </v-item>
                    </v-item-group>
                </v-container>
                <v-divider class="pb-1"></v-divider>
                <v-container>
                    <p class="mb-2 pl-1 text-left font-weight-bold">TAMANHO</p>

                    <v-item-group v-model="product.selectedSize" class="d-flex align-start flex-wrap">
                        <v-item 
                            v-slot="{ active, toggle }"
                            v-for="size in pr0.pr3List"
                            :key="size.pr3tamanho"
                        >
                            <v-card
                                color="white"
                                class="d-flex justify-center align-center mx-1 my-1"
                                :elevation="active ? 6 : 0"
                                :style="active ? `border: ${parameters.primaryColor} 2px solid !important` : 'border: #dcdcdc 1px solid !important'"
                                dark
                                max-width="36"
                                height="36"
                                width="36"
                                @click="toggle"
                            >
                                <v-card
                                    dark
                                    color="transparent"
                                    class="d-flex align-center"
                                    height="30"
                                    width="30"
                                    elevation="0"
                                >
                                    <div class="button flex-grow-1 text-center" :style="{color: parameters.primaryColor}">{{ size.pr3tamanho }}</div>
                                </v-card>
                            </v-card>
                        </v-item>
                    </v-item-group>
                </v-container>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-btn
                                :disabled="disableBuy"
                                block
                                text
                                outlined
                                tile
                                elevation="0"
                                :color="parameters.primaryColor"
                                @click="addVd3"
                                class="text-capitalize"
                            >
                                <v-icon>mdi-plus</v-icon> Adicionar combinação
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
                <v-divider class="pt-3"></v-divider>
            </v-container>
            <v-sheet class="text-center overflow-y-auto pt-0 pb-10" height="100%">
                <v-list dense>
                    <v-list-item>
                        <v-list-item-avatar class="ma-0">Cor</v-list-item-avatar>
                        <v-list-item-title>
                            <v-row>
                                <v-col cols="4">Tamanho</v-col>
                                <v-col cols="4">Quantidade</v-col>
                                <v-col cols="4">Valor</v-col>
                            </v-row>
                        </v-list-item-title>
                        <v-list-item-action></v-list-item-action>
                    </v-list-item>
                    <v-list-item v-if="product.vd3List.length == 0">
                        <v-list-item-title>Nenhuma combinação selecionada</v-list-item-title>
                    </v-list-item>
                    <template v-else v-for="(vd3, index) in product.vd3List">
                        <v-list-item :key="`${vd3.vd3opcao}-${vd3.vd3tamanho}`">
                            <v-list-item-avatar class="ma-0" :style="{backgroundColor: vd3.hexadecimal + ' !important'}" size="25px" style="border: #dcdcdc 3px solid">
                                <v-img 
                                    v-if="vd3 && vd3.cr1imagem"
                                    :src="vd3.cr1imagem"
                                    contain
                                    v-on:error="onImgError(vd3)"
                                />
                            </v-list-item-avatar>
                            <v-list-item-title>
                                <v-row>
                                    <v-col cols="4" class="d-flex align-center justify-center">{{ vd3.gr1desc }}</v-col>
                                    <v-col cols="4" class="d-flex align-center justify-center">
                                        <input v-model="vd3.vd3qtde" type="number" class="vd3qtde" :min="1"/>
                                    </v-col>
                                    <v-col cols="4" class="d-flex align-center justify-center">{{ vd3.vd5preco | money }}</v-col>
                                </v-row>
                            </v-list-item-title>
                            <v-list-item-action>
                                <v-btn icon @click="removeVd3(vd3)">
                                    <v-icon color="red">mdi-delete</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider
                            v-if="index < product.vd3List.length - 1"
                            :key="`divider-${vd3.vd3opcao}-${vd3.vd3tamanho}`"
                        ></v-divider>
                    </template>
                </v-list>
            </v-sheet>
        </v-card>
        <v-footer absolute>
            <v-btn
                block
                :color="parameters.secondaryColor"
                tile
                class="white--text text-button"
                style="color: white !important"
                @click="addToCart"
            >
                Adicionar ao carrinho
            </v-btn>
        </v-footer>
    </v-dialog>    
</template>

<script>
export default {
     data: () => ({
        cr1nome: "",
        selectedColor: undefined,
        selectedSize: undefined,
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        pr0: {
            get() {
                return this.$store.state.product.product;
            },
        },
        product: {
            get() {
                return this.$store.state.product;
            },
        },
        disableBuy(){
            return !this.pr0.pr2opcao || !this.pr0.pr3tamanho
        }
    },
    watch:{
        selectedColor(val){
            this.chooseColor(this.pr0.pr2List[val])
        },
        selectedSize(val){
            this.chooseSize(this.pr0.pr3List[val])
        }
    },
    methods:{
        addToCart(){
            this.$store.dispatch("cart/add", this.product.vd3List)
        },
        removeVd3(vd3){
            this.$store.dispatch("product/removeVd3", vd3)
        },
        chooseColor(pr2){
            this.$store.dispatch("product/chooseColor", pr2)
        },
        chooseSize(pr3){
            this.$store.dispatch("product/chooseSize", pr3)
        },
        addVd3(){
            this.$store.dispatch("product/addVd3", {
                "vd2produto": this.pr0.pr0produto,
                "vd3opcao": this.pr0.pr2opcao,
                "vd3tamanho": this.pr0.pr3tamanho,
                "vd3qtde": 1,
                "pr0etiq": this.pr0.pr0etiq,
                "vd5preco": this.pr0.pr0preco,
                "cr1nome": this.pr0.cr1nome,
                "hexadecimal": this.pr0.pr2List.filter(pr2 => pr2.pr2opcao == this.pr0.pr2opcao)[0].hexadecimal,
                "cr1imagem": this.pr0.pr2List.filter(pr2 => pr2.pr2opcao == this.pr0.pr2opcao)[0].cr1imagem,
                "gr1desc": this.pr0.gr1desc,
                "vd3foto": this.pr0.lstB2i.length > 0 ? this.pr0.lstB2i[0].b2icaminho : "../../../public/img/accountDefault.png",
                "vd3desc": this.pr0.pr0desc
            })
        },
        onImgError(prop){
            prop.cr1imagem = ""
        }
    }
};
</script>

<style>
</style>